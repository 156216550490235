<script>
// import vue2Dropzone from "vue2-dropzone";
// import "vue2-dropzone/dist/vue2Dropzone.min.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import Multiselect from "vue-multiselect";
import addAddress from '@/views/pages/contacts/Addressbook';
import Swal from "sweetalert2";
import axios from "@/Axiosconfig.js";

const newheaders = axios.defaults.headers;
newheaders["Content-Type"] = "multipart/form-data";
axios.defaults.headers = newheaders;
export default {
    props: {
        first_name: {
            type: String,
            required: true
        },
        last_name: {
            type: String,
            required: true
        },
        phone_number: {
            type: String,
            required: true
        },
        address: {
            type: String,
            default: ''
        },
        username: {
            type: String,
            default: ''
        },
        email: {
            type: String,
            required: true
        },
        gender: {
            type: String,
            default: 'Other'
        },
        customerType: {
            type: String,
            default: 'Individual'
        },
        account_type: {
            type: String,
            default: 'Customers'
        },
        customer_group: {
            type: Object,
            default: null
        },
        business: {
            type: Object,
            default: null
        },
        contactId: {
            type: String,
            default: ''
        },
        taxNumber: {
            type: String,
            default: 'N/A'
        },
        alternativeContact: {
            type: String,
            default: ''
        },
        landline: {
            type: String,
            default: 'N/A'
        },
        creditLimit: {
            type: Number,
            default: null
        },
        designation: {
            type: String,
            default: 'Mr'
        },
        modaltitle: String,
        editmode: Boolean,
        id: Number,
        myindex: Number,
    },
    components: {
        // vueDropzone: vue2Dropzone,
        Multiselect,
        addAddress,
    },
    data() {
        return {
            dropzoneOptions: {
                url: window.$http + "/customer/images/",
                autoProcessQueue: false,
                uploadMultiple: true,
                maxFilesize: 2, // maximum size of each file in MB
                acceptedFiles: "image/*", // restrict to image files only
                addRemoveLinks: true, // show remove button on uploaded files
                dictDefaultMessage: 'Drop Category display image here or click to upload',
                maxFiles: 10,
                headers: window.$headers,
            },
            title: "Add Customer",
            items: [
                {
                    text: "USER: Admin",
                },
                {
                    text: "Add Customer",
                    active: true,
                },
            ],
            showAdvanced:false,
            account_types:['Customers','Suppliers','Customers & Suppliers'],
            customer_groups:[],
            genders: {
                'Male': 'Male',
                'Female': 'Female',
                'Other': 'Other'
            }
        };
    },
    mounted() {
        this.updatearray();
    },
    methods: {
        updatearray() {
            axios
                .get(window.$http+"pickup_stations",{headers:window.$headers})
                .then((res) => {
                    this.addresses = res.data['results']
                    axios.get(window.$http+"customer_groups",{headers:window.$headers})
                    .then((res) => {
                        this.customer_groups = res.data['results']
                    })
                })
                .catch((e) => {
                    this.errors.push(e);
                });
        },
        addRec() {
            if (this.first_name.trim() == "") {
                Swal.fire("Please enter First Name!");
                return;
            }
            if (this.last_name.trim() == "") {
                Swal.fire("Please enter Lat Name!");
                return;
            }
            if (this.email.trim() == "") {
                Swal.fire("Please enter Email!");
                return;
            }
            var data = {
                contact_id:this.contactId||'',
                designation:this.designation,
                customer_group:this.customer_group?this.customer_group.id:null,
                address:this.address?this.address.id:null,
                first_name:this.first_name?this.first_name:this.business,
                last_name:this.last_name?this.last_name:this.business,
                landline:this.landline,
                contact_type:this.customerType,
                account_type:this.account_type,
                business:this.business,
                tax_number:this.taxNumber,
                credit_limit:this.creditLimit,
                phone:this.phone_number,
                alternative_contact:this.alternativeContact,
            };
            axios
                .post(window.$http+"contacts/", data,{headers:window.$headers})
                .then(() => {
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title:'Success',
                        html: "Your work has been saved. Customer " + this.first_name + " Added",
                        showConfirmButton: false,
                        timer: 3000,
                    }).then((result) => {
                        result;
                        this.clearvalues();
                    });
                })
                .catch((e) => {
                    console.log(e)
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: "An error occured while adding entries!",
                        html:e.toString(),
                        showConfirmButton: false,
                        timer: 5000,
                    });
                });
        },
        handleSubmit() {
            console.log("Error on submit");
        },
        editRec() {
            if (this.first_name.trim() == "") {
                Swal.fire("Please enter First Name");
                return;
            }
            var data = {
                id:this.id,
                contact_id:this.contactId,
                designation:this.designation,
                customer_group:this.customer_group?this.customer_group.id:null,
                address:this.address?this.address.id:null,
                first_name:this.first_name?this.first_name:this.business,
                last_name:this.last_name?this.last_name:this.business,
                landline:this.landline,
                contact_type:this.customerType,
                account_type:this.account_type,
                business:this.business,
                tax_number:this.taxNumber,
                credit_limit:this.creditLimit,
                phone:this.phone_number,
                alternative_contact:this.alternativeContact,
            };
            axios
                .put(window.$http+"contacts/" + this.id + "/", data,{headers:window.$headers})
                .then(() => {
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Your work has been saved. Customer " + data.first_name,
                        showConfirmButton: false,
                        timer: 1500,
                    }).then((result) => {
                        result;
                        this.orderData[this.myindex].first_name = this.first_name;
                        this.orderData[this.myindex].last_name = this.last_name;
                    });
                })
                .catch((e) => {
                    console.log(e)
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: "An error occured while adding entries!",
                        html:e.toString(),
                        showConfirmButton: false,
                        timer: 5000,
                    });
                });

        },
        toggleAdvanced() {
      this.showAdvanced = !this.showAdvanced;
    },
    },
};
</script>

<template>
    <div class="container-fluid p-4 m-4">
        <form @submit.prevent="handleSubmit">
            <div class="row  align-items-center justify-content-center">
                <div class="col-sm-4">
                    <div class="mb-0">
                        <label for="account_type">Account Type</label>
                        <multiselect v-model="account_type" :options="account_types"
                            placeholder="Select Account Type" :multiple="false" :editable="true">
                        </multiselect>
                    </div>
                </div>
                <div class="col-sm-4" v-if="account_type ==='Customers'">
                    <div class="mb-0">
                        <label for="account_type">Customer Group</label>
                        <multiselect v-model="customer_group" :options="customer_groups" :option-label="cr => cr.group_name"
                                placeholder="Select Customer Group" label="group_name" track-by="id" :multiple="false" :editable="true">
                        </multiselect>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                    <label for="contactId">Contact ID</label>
                    <input type="text" class="form-control" id="contactId" placeholder="Leave blank to auto generate" v-model="contactId">
                    </div>
                    </div>
                <div class="col-sm-4" v-if="customerType ==='Individual'">
                <div class="form-group">
                    <label for="designation">Prefix</label>
                    <input type="designation" class="form-control" id="designation" placeholder="Mr/Mrs/Ms..." v-model="designation" required>
                </div>
                </div>
                <div class="col-sm-4" v-if="customerType ==='Individual'">
                <div class="form-group">
                    <label for="first_name">First Name</label>
                    <input type="first_name" class="form-control" id="first_name" v-model="first_name" required>
                </div>
                </div>
                <div class="col-sm-4" v-if="customerType ==='Individual'">
                <div class="form-group">
                    <label for="last_name">Last Name</label>
                    <input type="last_name" class="form-control" id="last_name" v-model="last_name" required>
                </div>
                </div>
                <div class="col-sm-4" v-if="customerType === 'Business'">
                    <div class="form-group">
                        <label for="business">Business Name</label>
                        <input type="text" class="form-control" id="business" v-model="business">
                    </div>
                    </div>
                <div class="col-sm-4">
                <div class="form-group">
                    <label for="email">Email</label>
                    <input type="email" class="form-control" id="email" v-model="email" required>
                </div>
                </div>
                <div class="col-sm-4">
                <div class="form-group">
                    <label for="phone">Mobile</label>
                    <input type="text" class="form-control" id="phone" v-model="phone_number" required>
                </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                    <label for="alternativeContact">Alternative Contact</label>
                    <input type="text" class="form-control" id="alternativeContact" v-model="alternativeContact">
                    </div>
                    </div>
                    <div class="col-sm-4" v-if="customerType ==='Business'">
                    <div class="form-group">
                    <label for="landline">Landline</label>
                    <input type="text" class="form-control" id="landline" v-model="landline">
                    </div>
                    </div>
                <div class="col-sm-4" v-if="customerType ==='Individual'">
                <div class="form-group">
                    <label for="gender">Gender</label>
                    <select class="form-control" id="gender" v-model="gender">
                    <option v-for="(value, label) in genders" :value="value" :key="value">{{ label }}</option>
                    </select>
                </div>
                </div>
                <div class="col-sm-4">
                <div class="form-group">
                    <label for="customerType">Contact Type</label>
                    <select class="form-control" id="customerType" v-model="customerType">
                    <option value="Individual">Individual</option>
                    <option value="Business">Business</option>
                    </select>
                </div>
                </div>
                <div class="col-sm-4 mt-4">
                    <div class="form-group">
                    <a type="button" class="uil uil-drop-down p-2 rounded bg-light link" @click="toggleAdvanced">Additional Information</a>
                </div>
                </div>
                <div v-if="showAdvanced">
                    <div class="row">
                        <div class="col-sm-4">
                        <div class="mb-0">
                            <label for="suppliername">Address<button class="badge badge-pill bg-primary"
                                    v-b-modal.modal-address><i class="fa fa-plus"></i> Add New</button></label>
                            <multiselect v-model="address" :options="addresses" :option-label="addr => addr.pickup_location"
                                placeholder="Search an address" label="pickup_location" track-by="id" :multiple="false" :editable="true">
                            </multiselect>
                        </div>
                    </div>
                    <div class="col-sm-4">
                    <div class="form-group">
                    <label for="taxNumber">Tax Number</label>
                    <input type="text" class="form-control" id="taxNumber" v-model="taxNumber">
                    </div>
                    </div>
                    <div class="col-sm-4">
                    <div class="form-group">
                    <label for="creditLimit">Credit Limit</label>
                    <input type="text" class="form-control" id="creditLimit" v-model="creditLimit">
                    </div>
                    </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="row">
            <div class="col-sm-10"></div>
            <div class="col-sm-2">
                <b-button v-show="!editmode" variant="success" @click="addRec()">Save Contact</b-button>
                <b-button v-show="editmode" variant="success" @click="editRec()">Update Customer</b-button>
            </div>
        </div>
        <!--modals-->
        <div class="card">
            <b-modal :title="modaltitle" size="lg" id="modal-address">
                <addAddress @getuserAddress="updatearray()" :editmode="false" :selectmode="false" :modaltitle="''" :id="0"
                    :selectedCity="null" :selectedTown="selectedTown" :zip_code="zip_code" :first_name="first_name"
                    :last_name="last_name" :phone_number="phone_number" :other_phone="other_phone"
                    :delivery_address="delivery_address" :default_address="default_address" />
            </b-modal>
        </div>
    </div>
</template>